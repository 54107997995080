import React from 'react'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import SEO from '../components/SEO'

const EnglishPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>Latam Headless Commerce Week</title>
      </Helmet>

      <SEO title="Latam Headless Commerce Week" />

      <div className="bg-main bg-black text-white">
        <header className="py-6 bg-black">
          <nav className="container mx-auto flex justify-between">
            <img className="w-18 desktop:w-auto" src="../img/Reign-logo.svg" alt="" />
            <a className="cursor-pointer" onClick={() => navigate('/es')}>
              ES
            </a>
          </nav>
        </header>
        <main>
          <section className="mb-10 desktop:mb-10">
            <div className="container mx-auto text-center">
              <img
                src="../img/Logo-evento.svg"
                alt=""
                className="inline-block mb-5 desktop:mb-10 pt-5 desktop:pt-15 w-72 desktop:w-auto"
              />
              <img src="../img/logo-bajada-ingles.svg" alt="" className="inline-block" />
            </div>
          </section>
          {/* DATE SECTION */}
          <section className="mb-5 desktop:mb-20  bg-resume">
            <div className="container mx-auto text-center">
              <p className="px-2 text-base leading-6 desktop:text-4xl desktop:leading-14">
                For 5 days we will talk about the best <span className="font-weight-bold">API-First platforms</span>,
                and those that are shining in the <span className="font-weight-bold">Headless Ecommerce space</span>.
              </p>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-sponsors">
            <div className="container mx-auto text-center">
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <p className="px-2 text-base leading-6 desktop:text-4xl desktop:leading-14">
                  We will discuss success cases in ecommerce solutions developed by Reign for world class brands
                </p>
              </div>
            </div>
          </section>
          {/* SPONSOR SECTION */}
          <section className="mb-15 desktop:mb-40 bg-sponsors">
            <div className="container mx-auto">
              <h3 className="title">
                <span className="font-weight-bold">Our </span>
                Speakers
              </h3>
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <div className="grid desktop:grid-cols-6 gap-8 desktop:gap-y-16 justify-items-center">
                  <div className="desktop:col-span-2  desktop:col-start-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-contentful.svg" alt="" />
                    <span>API-first content platform to build digital experiences</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-bigcommerce.svg" alt="" />
                    <span>Ecommerce for a new era</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-algolia.svg" alt="" />
                    <span>Site search &amp; discovery powered by AI</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-jamstack.svg" alt="" />
                    <span>The modern way to build Websites and Apps that delivers better performance</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-netlify.svg" alt="" />
                    <span>Develop &amp; deploy the best web experiences in record time</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-commercelayer.svg" alt="" />
                    <span>Headless commerce for global brands</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-4Geek.svg" alt="" />
                    <span>The #1 Top coding bootcamp with over 1,000 graduates and life-long career support</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-elasticpath.svg" alt="" />
                    <span>Leaders in Headless Commerce microservices</span>
                  </div>
                  <div className="desktop:col-span-2 desktop:col-start-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-insider.svg" alt="" />
                    <span>The first integrated Growth Management platform</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-reblaze.svg" alt="" />
                    <span>Unified web protection, running natively on your prefered cloud platform.</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* PROGRAM SECTION */}
          <section className="mb-15 desktop:mb-40 bg-program">
            <div className="container mx-auto">
              <h3 className="title font-weight-bold">Program</h3>
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <div className="grid desktop:grid-cols-2 gap-16 gap-y-8">
                  <div className="col-span-1 text-center desktop:text-left">
                    <span className="text-sm desktop:text-4xl font-weight-bold">26 - 30 April </span>
                    <p className="text-base desktop:text-2xl mt-4 desktop:mt-20">
                      A week to talk about headless. Every day has different topics and industry speakers with success
                      cases from Reign
                    </p>
                  </div>
                  <div className="col-span-1">
                    <ul className="grid justify-center gap-y-2 desktop:gap-y-12 list-none">
                      <li className="flex items-center">
                        <img src="../img/Bandera-Chile.svg" alt="" className="w-6 mr-2 desktop:w-auto desktop:mr-6" />
                        <span className="text-sm desktop:text-3xl">(CL) 13:00 hrs</span>
                      </li>
                      <li className="flex items-center">
                        <img
                          src="../img/Bandera-Colombia.svg"
                          alt=""
                          className="w-6 mr-2 desktop:w-auto desktop:mr-6"
                        />
                        <span className="text-sm desktop:text-3xl">(CO) 12:00 hrs</span>
                      </li>
                      <li className="flex items-center">
                        <img src="../img/Bandera-Mexico.svg" alt="" className="w-6 mr-2 desktop:w-auto desktop:mr-6" />
                        <span className="text-sm desktop:text-3xl">(MX) 12:00 hrs</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="/../img/day-1.svg" alt="" />
              <h4 className="date-subtitle">26 April</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Headless 101</span>
                <br />3 different approaches
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg">
                    On day one we have 3 approaches for Headless. JAMstack, MACH Alliance and Elastic Path. We also have a talk about why choosing a Headless CMS with Contentful.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Jamstack.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        JAMstack
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">In a very simple way Phil Hawksworth explains us how JAMStack simplifies the development of frontends for headless solutions.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=162s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Elasticpath.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Elastic Path
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Harry Chemko and Shaneil Lafayette from Elastic Path tell us about Composable Commerce.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=3450s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/MachAlliance.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Mach Alliance - Commercetools
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Kelly Goetsch invites us to know how MACH Alliance helps to promote Composable Commerce technologies and Headless solutions.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=2201s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Contentful.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Contentful
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Andrew Kumar tells us about the importance and benefits of Headless when choosing a CMS.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=5789s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40">
            <div className="container mx-auto text-right flex flex-col items-center desktop:items-end">
              <img className="day-image" src="../img/day-2.svg" alt="" />
              <h4 className="date-subtitle">27 April</h4>
              <h3 className="text-lg text-center desktop:text-right leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Dive in Headless Solutions</span>
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="col-end-4 mt-5 desktop:mt-12 text-center desktop:text-right">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    Day two goes deeper in the benefits of a headless ecosystem, and we learn some specifics from BigCommerce, Netlify and Reblaze.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8 text-left">
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bigcommerce.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Big Commerce
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Big Commerce CSO tells us about how they evolved to be an OPEN SaaS Platform and thus offer a Headless solution to the ecommerce world.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=154s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/netlify.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Netlify
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">In his second talk, Phil Hawksworth talks about how Netlify drives decoupled architectures.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=2011s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Reblaze.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Reblaze
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">It's not only development, but also security. Our partners at Reblaze  talk about their security layer for cloud architectures.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=4228s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-3">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="../img/day-3.svg" alt="" />
              <h4 className="date-subtitle">28 April</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">The death of old school ecommerce</span>
                <br />
                Headless Ecommerce
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    A talk about how we built Coca Cola's Embonor Ecommerce, with a little help from our stack partners in this project: Algolia, Commerce Layer &amp; Insider.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Embonor.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Embonor
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Embonor's Digital Channel Manager shows us the Embonor Mi Coca Cola portal and comments on some anecdotes from the development process.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=85s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/commercelayer.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Commerce Layer
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Discover the contribution of Commerce Layer to the Embonor stack, as the ecommerce engine of the Mi Coca-Cola portal.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=1551s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Algolia.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Algolia
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Learn the importance of searches in an ecommerce with Algolia and see how this platform applies AI to its results algorithms.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=2345s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Insider.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Insider
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">We will learn from Bruna Blando's how did  Insider managed to boost Embonor's ecommerce sales.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=3701s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-4">
            <div className="container mx-auto text-right flex flex-col items-center desktop:items-end">
              <img className="day-image" src="../img/day-4.svg" alt="" />
              <h4 className="date-subtitle">29 April</h4>
              <h3 className="text-lg text-center desktop:text-right leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Headless</span>
                <br />
                Autonomy and Governance
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="col-end-4 mt-5 desktop:mt-12 text-center desktop:text-right">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    With some help from Contentful we tell you the story of BUPA and how easy is to work on content and marketing with a headless CMS. We also review some special features of Big Commerce.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8 text-left">
                <div>
                <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Contentful.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Contentful
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Andrew Kumar asks us: is a legacy platform standing on the way of your future?</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=121s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bupa.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Bupa
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">The head of Digital Experience tells us about the BUPARIZATION project that we carry out for the sites of all Bupa companies. This project involved the good use of Contentful and Atomic Design.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=961s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bigcommerce.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          BigCommerce
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">The VP of BigCommerce Partnerships tells us about his solution to develop B2B platforms.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=2377s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-5">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="../img/day-5.svg" alt="" />
              <h4 className="date-subtitle">30 April</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Organisms, molecules and atoms</span>
                <br />
                Our own Atomic Design
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    Let's practice Atomic Design! Reign's UX/UI team starts with some of the Atomic Design principles and how to apply them in an ecommerce project. To round it up, Friends of Figma shows some special tips on how to be Atomic with Figma.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Reign-UX.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Reign
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Reign's Head of UX  tells us why we have adopted Atomic Design as the main axis in all our developments, showing us interesting examples.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=111s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Reign-UI.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Reign
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Two of our Rockstars from the UI show us an example of Atomic Design applied to ecommerce.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=1116s" target="_blank" className="rd-btn-sm">watch video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/FriendsOfFigma.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Friends of Figma
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">How about learning Figma and Atomic Design with Friends of Figma? Here we learn some of the benefits of using this tool for Atomic Design too!</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=2652s" target="_blank" className="rd-btn-sm">watch video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="bg-black">
          <div className="container mx-auto py-10">
            <div className="grid desktop:grid-cols-4 gap-4 text-lg desktop:text-2xl text-center desktop:text-left">
              <div className="text-center">
                <img src="../img/ico-mapamundi.svg" alt="" className="inline desktop:block mb-5 desktop:mb-0" />
              </div>
              <div>
                <h4 className="font-bold mb-3">Latin America</h4>
                <h5 className="mb-5">hola@reign.cl</h5>
                <h4 className="font-bold mb-3">North America</h4>
                <h5 className="mb-5">hello@reign.cl</h5>
                <span className="text-xs desktop:text-lg">
                  Los Conquistadores 1730
                  <br />
                  Of. 2301 Santiago, Chile
                </span>
              </div>
              <div />
              <div>
                <h4 className="mb-5 font-bold">Follow us</h4>
                <div className="flex justify-center desktop:justify-start">
                  <a href="https://www.linkedin.com/company/reigncl" target="_blank" className="mr-5">
                    <img src="../img/linkedin.svg" alt="" />
                  </a>
                  <a href="https://medium.com/reigncl" target="_blank">
                    <img src="../img/medium.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default EnglishPage
